import React from "react";
import {createRoot} from "react-dom/client";


import {CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
import {App} from "./App";

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Tooltip);
Chart.register(Legend);


const root = createRoot(document.getElementById("root")!);
root.render(<App/>);

