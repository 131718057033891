export const routes = {
    home: {
        pattern: "/",
        reverse: () => "/",
    },

    login: {
        pattern: "/login",
        reverse: () => "/login",
    },
}