import React, {useMemo} from "react";
import {Line} from "react-chartjs-2";
import {format as formatDate} from "date-fns";
import {useChartData} from "../api";
import {Loading} from "./LoadingPlate";
import {ChartData, ChartDataset, ChartEvent, ChartOptions, LegendItem} from "chart.js";
import {Bid, Candle, KindParams, Label, Period, RangeParams} from "../entities";
import {createHiddenChartsStore, usePeriod} from "../storage";
import {map} from "ramda";

const values = map((c: Candle) => c.value || null);

const KINDS: { [kindKey: string]: KindParams } = {
    "garantex:usdtrub:sell": {label: "Grx (sell)", color: "#ef3f3f", type: "price", func: values},
    "garantex:usdtrub:buy": {label: "Grx (buy)", color: "#18c022", type: "price", func: values},
    "garantex:usdtrub:rate": {label: "Grx (rate)", color: "#0588da", type: "price", hidden: true, func: values},

    "profinance:usdrub:rate": {label: "Prof", color: "#72bcff", type: "price", hidden: true, func: values},

    "bcs:usdrub:sell": {label: "BCS (sell)", color: "#d03d3d", type: "price", func: values},
    "bcs:usdrub:buy": {label: "BCS (buy)", color: "#2eb737", type: "price", func: values},

    "garantex:usdtrub:sell_factor": {label: "Grx (sell factor)", color: "#ef3f3f", type: "percent", func: values},
    "garantex:usdtrub:buy_factor": {label: "Grx (buy factor)", color: "#18c022", type: "percent", func: values},
}

const RANGES: { [key in Period]: RangeParams } = {
    "1h": {label: "Час", ms: 1000 * 60 * 60, window: "10s", format: "HH:mm"},
    "24h": {label: "День", ms: 1000 * 60 * 60 * 24, window: "10m", format: "HH:mm"},
    "168h": {label: "Неделя", ms: 1000 * 60 * 60 * 24 * 7, window: "1h", format: "dd HH"},
    "720h": {label: "Месяц", ms: 1000 * 60 * 60 * 24 * 30, window: "4h", format: "MM/dd"},
    "8544h": {label: "Год", ms: 1000 * 60 * 60 * 24 * 365, window: "24h", format: "MM/dd"},
}


const verticalLine = {
    afterDraw: (chart: any) => {
        if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.y;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#919191';
            ctx.stroke();
            ctx.restore();
        }
    }
}

export const ChartPlate: React.FC = () => {
    const {period, setPeriod} = usePeriod("24h");

    const {
        loading,
        plot,
        error,
    } = useChartData(period, RANGES[period].window);


    const labels = useMemo(() => buildChartLabels(plot.labels), [plot?.labels]);
    const items = useMemo(() => sortItems(plot.items), [plot?.items])

    const [priceData, percentData] = useMemo(() => getChartData(labels, items, period), [items, labels])
    const [priceOpts, percentOpts] = useMemo(() => getChartOptions(labels, period), [period, labels])


    return (
        <div className="card shadow mb-5">
            <div className="card-header d-flex justify-content-between">
                <h3>График</h3>

                <div className="btn-group ml-auto">
                    {Object.entries(RANGES).map(([p, def]) => (
                        <button
                            key={p}
                            type="button"
                            className={`btn btn-sm btn-outline-secondary ${period === p ? "active" : ""}`}
                            onClick={() => setPeriod(p as Period)}
                        >
                            {def.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className="card-body">
                {loading ? (
                    <Loading/>
                ) : (
                    <div>
                        <Line
                            options={priceOpts}
                            data={priceData}
                            plugins={[verticalLine as any]}
                        />

                        <br/>

                        <Line
                            options={percentOpts}
                            data={percentData}
                            plugins={[verticalLine as any]}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

function getChartOptions(labels: string[], period: Period): ChartOptions<"line">[] {
    const hiddenChartsStore = createHiddenChartsStore(Object.values(KINDS));

    const common: ChartOptions<"line"> = {
        responsive: true,
        // aspectRatio: 1.5,
        animation: false,
        plugins: {
            tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
                position: "average",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                usePointStyle: true,
                callbacks: {
                    title(tooltipItems) {
                        const item = tooltipItems[0];
                        return formatDate(new Date(item.label), "dd.MM.yyyy HH:mm");
                    }
                }
            },
            legend: {
                onClick: function (e: ChartEvent, item: LegendItem) {
                    const chart = this.chart;
                    const index = item.datasetIndex;

                    if (item.hidden) {
                        chart.show(index!);
                    } else {
                        chart.hide(index!);
                    }

                    item.hidden = !item.hidden;
                    hiddenChartsStore.setHiddenState(chart.data.datasets![index!].label!, item.hidden!);
                    chart.update();
                }
            },
        }
    }

    const ticks = {
        callback: (value: string | number) => {
            if (typeof value === "string") {
                return value;
            }

            return formatDate(new Date(labels[value]), RANGES[period].format);
        },
    }

    return [
        {
            ...common,
            scales: {
                y: {
                    ticks: {
                        callback: (value) => (value as number).toFixed(2) + "₽",
                    }
                },
                x: {
                    ticks,
                }
            },
        },
        {
            ...common,
            scales: {
                y: {
                    ticks: {
                        callback: (value) => (value as number).toFixed(2) + "%",
                    },
                    // max: 12,
                    // min: 0,
                },
                x: {
                    ticks,
                }
            },
        }
    ]
}

function getChartData(labels: string[], items: Record<string, Candle[]>, period: Period): ChartData<"line">[] {
    const hiddenChartsStore = createHiddenChartsStore(Object.values(KINDS));

    // if (dataByPlatforms.hasOwnProperty("garantex-buy")) {
    //     dataByPlatforms["garantex-rate"] = buildRateFromBuy(dataByPlatforms["garantex-buy"]);
    //     dataByPlatforms["garantex-buy-factor"] = buildFactorPlot(dataByPlatforms["garantex-buy"]);
    // }
    //
    // if (dataByPlatforms.hasOwnProperty("garantex-sell")) {
    //     dataByPlatforms["garantex-sell-factor"] = buildFactorPlot(dataByPlatforms["garantex-sell"]);
    // }

    const priceDatasets: ChartDataset<"line">[] = [];
    const percentsDatasets: ChartDataset<"line">[] = [];

    for (const [kindKey, candles] of Object.entries(items)) {
        const params = KINDS[kindKey];
        const dataset: ChartDataset<"line"> = {
            label: params.label,
            data: params.func(candles),
            hidden: hiddenChartsStore.getHiddenState(params.label),
            cubicInterpolationMode: 'monotone',
            tension: 0.4,

            fill: 0.3,
            backgroundColor: params.color,
            borderColor: params.color,
            borderWidth: 2,

            pointRadius: 0,
            spanGaps: true,
        }

        if (params.type === "price") {
            priceDatasets.push(dataset);
        } else {
            percentsDatasets.push(dataset);
        }
    }

    return [
        {
            labels,
            datasets: priceDatasets,
        },
        {
            labels,
            datasets: percentsDatasets,
        }
    ]
}


function buildChartLabels(labels: Label[]): string[] {
    return labels.map(l => l.to);
}


// Deprecated
function splitPlatforms(list: Bid[]): Record<string, Bid[]> {
    const datasets: Record<string, Bid[]> = {};

    for (const bid of list) {
        const key = bid.meta.platform + '-' + bid.meta.kind;

        if (!datasets[key]) {
            datasets[key] = [];
        }

        datasets[key].push(bid);
    }

    return datasets;
}

// Deprecated
function sortPlatforms(list: Record<string, Bid[]>): Record<string, Bid[]> {
    const platforms = Object.keys(KINDS);

    let entries = Object.entries(list).sort(([a], [b]) => {
        return platforms.indexOf(a) - platforms.indexOf(b)
    })

    return Object.fromEntries(entries);
}

function sortItems(list: Record<string, Candle[]>): Record<string, Candle[]> {
    const platforms = Object.keys(KINDS);

    let entries = Object.entries(list).sort(([a], [b]) => {
        return platforms.indexOf(a) - platforms.indexOf(b)
    })

    return Object.fromEntries(entries);
}

// Deprecated
function buildRateFromBuy(bids: Bid[]): Bid[] {
    return bids.map(b => {
        const price = b.price / (1 + b.factor);

        return {
            meta: {
                kind: "rate",
                platform: b.meta.platform,
                pair: b.meta.pair,
            },
            time: b.time,
            price,
            factor: 0,
            volume: 0,
        }
    })
}

// Deprecated
function buildFactorPlot(bids: Bid[]): Bid[] {
    return bids.map(b => {
        return {
            meta: {
                kind: "factor",
                platform: b.meta.platform,
                pair: b.meta.pair,
            },
            time: b.time,
            price: 0,
            factor: b.factor,
            volume: b.volume,
        }
    })
}

// Deprecated
function buildLabels(start: number, end: number, window: number, format: string): string[] {
    const result: string[] = [];
    for (let x = start; x < end; x += window) {
        result.push((new Date(x)).toISOString());
    }

    return result;
}

// Deprecated
function interpolate(start: number, end: number, window: number, bids: Bid[]): (number | null)[] {
    const result: (number | null)[] = [];

    const buckets: Map<number, Bid[]> = new Map();
    for (const bid of bids) {
        let t = new Date(bid.time).getTime();
        t = t - t % window;

        const bucket = buckets.get(t) || [];
        bucket.push(bid);
        buckets.set(t, bucket);
    }

    let last: number | null = null;
    for (let x = start; x < end; x += window) {
        const bucket = buckets.get(x);
        if (!bucket || bucket.length === 0) {
            // result.push(null);
            result.push(last);
            continue;
        }

        const y = sumBucket(bucket);
        result.push(y);
        last = y;
    }


    return result;
}

// Deprecated
function sumBucket(bids: Bid[]): number | null {
    if (bids.length === 0) return null;

    const b = bids[0];
    switch (b.meta.kind) {
        case "rate":
            return Math.max(...bids.map(bid => bid.price));

        case "buy": {
            const totalVolume = bids.reduce((acc, bid) => acc + bid.volume, 0);
            if (totalVolume == 0) {
                // Old garantex records do not have volume (set to 0)
                return Math.min(...bids.map(bid => bid.price));
            }

            return bids.reduce((acc, bid) => acc + bid.price * bid.volume / totalVolume, 0);
        }

        case "factor": {
            const totalVolume = bids.reduce((acc, bid) => acc + bid.volume, 0);
            if (totalVolume == 0) {
                // Old garantex records do not have volume (set to 0)
                return Math.min(...bids.map(bid => bid.factor)) * 100;
            }

            return bids.reduce((acc, bid) => acc + bid.factor * bid.volume / totalVolume, 0) * 100;
        }

        default:
            return Math.max(...bids.map(bid => bid.price));
    }
}
