import React, {useState} from "react";
import {login} from "../api";


export const LoginPlate: React.FC = () => {
    const [username, setUsername] = useState("")
    const [password, setFactorStep] = useState("")
    const [working, setWorking] = useState(false)
    const [error, setError] = useState(false)
    const [invalid, setInvalid] = useState(false)

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setError(false)
        setInvalid(false)
        setWorking(true)

        login(username, password)
            .then(() => {
                window.location.replace("/")
            })
            .catch((err) => {
                setWorking(false);

                debugger;
                if (err.response?.status === 401) {
                    setInvalid(true)
                } else {
                    setError(true)
                }
            })
    }

    return (
        <div className="card shadow mb-5">
            <div className="card-header">
                <h3>Авторизация</h3>
            </div>
            <div className="card-body">

                <form action="/" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Имя пользователя</label>
                        <input
                            type="text"
                            className="form-control"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            onFocus={e => e.target.select()}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Пароль</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={e => setFactorStep(e.target.value)}
                            onFocus={e => e.target.select()}
                        />
                    </div>

                    {invalid && (
                        <div className="alert alert-danger" role="alert">
                            Неверный логин или пароль
                        </div>
                    )}

                    {error && (
                        <div className="alert alert-danger" role="alert">
                            Произошла ошибка, попробуйте позже
                        </div>
                    )}

                    <button
                        disabled={working}
                        className="btn btn-primary"
                        type="submit"
                    >
                        {working ? "Входим..." : "Вход"}
                    </button>
                </form>
            </div>
        </div>
    )
}