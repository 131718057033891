import React from "react";
import styled from "styled-components";
import {ConfigPlate} from "../components/ConfigPlate";
import {ChartPlate} from "../components/ChartPlate";


const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
`

export const HomePage: React.FC = () => {
    return (
        <Main>
            <h1 className="mb-5">Cryprorates tracker</h1>

            <ChartPlate/>

            <ConfigPlate/>
        </Main>
    )
}
