import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import {routes} from "./routes";
import {HomePage} from "./pages/HomePage";
import {LoginPage} from "./pages/LoginPage";


export const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path={routes.home.pattern} element={<HomePage/>}/>
                <Route path={routes.login.pattern} element={<LoginPage/>}/>
            </Routes>
        </Router>
    )
}
