import React, {useEffect, useState} from "react";
import {useConfig} from "../api";
import {Loading} from "./LoadingPlate";

function ftos(val: number | undefined): string {
    if (val === undefined) return "";
    return (val * 100).toFixed(2).replace(".", ",");
}

function itos(val: number | undefined): string {
    if (val === undefined) return "";
    return (val).toFixed(2).replace(".", ",");
}


function stof(val: string): number {
    return parseFloat(val.replace(",", ".")) / 100;
}

function stoi(val: string): number {
    return parseFloat(val.replace(",", "."));
}

export const ConfigPlate: React.FC = () => {
    const {
        loading,
        config,
        save,
        saving,
        saved,
    } = useConfig()

    const [strMinFactor, setStrMinFactor] = useState("")
    const [strMaxFactor, setStrMaxFactor] = useState("")
    const [strFactorStep, setFactorStep] = useState("")
    const [strMinPrice, setStrMinPrice] = useState("")
    const [strMaxPrice, setStrMaxPrice] = useState("")
    const [strMinVolume, setStrMinVolume] = useState("")

    useEffect(() => {
        setStrMinFactor(ftos(config.min_factor));
        setStrMaxFactor(ftos(config.max_factor));
        setFactorStep(ftos(config.factor_step));
        setStrMinPrice(itos(config.min_price));
        setStrMaxPrice(itos(config.max_price));
        setStrMinVolume(itos(config.min_volume));
    }, [loading, config])

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const minFactor = stof(strMinFactor);
        const maxFactor = stof(strMaxFactor);
        const factorStep = stof(strFactorStep);
        const minPrice = stoi(strMinPrice);
        const maxPrice = stoi(strMaxPrice);
        const minVolume = stoi(strMinVolume);

        if (isNaN(minFactor)) {
            alert("Некорректное значение минимального фактора")
            return;
        }

        if (isNaN(maxFactor)) {
            alert("Некорректное значение максимального фактора")
            return;
        }

        if (isNaN(factorStep)) {
            alert("Некорректное значение шага фактора")
            return;
        }

        if (factorStep <= 0) {
            alert("Шаг фактора должен быть больше нуля")
            return;
        }

        if (isNaN(minPrice)) {
            alert("Некорректное значение минимальной цены")
            return;
        }

        if (isNaN(maxPrice)) {
            alert("Некорректное значение максимальной цены")
            return;
        }

        if (maxPrice <= minPrice) {
            alert("Максимальная цена должна быть больше минимальной")
            return;
        }

        save({
            min_factor: minFactor,
            max_factor: maxFactor,
            factor_step: factorStep,
            min_price: minPrice,
            max_price: maxPrice,
            min_volume: minVolume,
        })
    }

    return (
        <div className="card shadow mb-5">
            <div className="card-header">
                <h3>Конфигурация</h3>
            </div>
            <div className="card-body">
                {loading ? (
                    <Loading/>
                ) : (
                    <form action="/" onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="" className="form-label">Минимальное значение фактора (%)</label>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control"
                                    value={strMinFactor}
                                    onChange={e => setStrMinFactor(e.target.value)}
                                    onFocus={e => e.target.select()}
                                />
                                <small className="text-muted">
                                    Минимальное значение фактора Grx(sell) ниже которого бот не будет реагировать на изменения
                                </small>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="" className="form-label">Максимальное значение фактора (%)</label>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control"
                                    value={strMaxFactor}
                                    onChange={e => setStrMaxFactor(e.target.value)}
                                    onFocus={e => e.target.select()}
                                />
                                <small className="text-muted">
                                    Максимальное значение фактора Grx(buy) выше которого бот не будет реагировать на изменения
                                </small>
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="" className="form-label">Шаг фактора (%)</label>
                            <input
                                type="text"
                                inputMode="decimal"
                                className="form-control"
                                value={strFactorStep}
                                onChange={e => setFactorStep(e.target.value)}
                                onFocus={e => e.target.select()}
                            />
                            <small className="text-muted">
                                Шаг изменения фактора.
                            </small>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="" className="form-label">Минимальная цена (₽)</label>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control"
                                    value={strMinPrice}
                                    onChange={e => setStrMinPrice(e.target.value)}
                                    onFocus={e => e.target.select()}
                                />
                                <small className="text-muted">
                                    Отсечка по цене
                                </small>
                            </div>

                            <div className="col-md-4 mb-3">
                                <label htmlFor="" className="form-label">Максимальная цена (₽)</label>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control"
                                    value={strMaxPrice}
                                    onChange={e => setStrMaxPrice(e.target.value)}
                                    onFocus={e => e.target.select()}
                                />
                                <small className="text-muted">
                                    Отсечка по цене
                                </small>
                            </div>

                            <div className="col-md-4 mb-3">
                                <label htmlFor="" className="form-label">Минимальная объем ($)</label>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control"
                                    value={strMinVolume}
                                    onChange={e => setStrMinVolume(e.target.value)}
                                    onFocus={e => e.target.select()}
                                />
                                <small className="text-muted">
                                    Отсечка по объему
                                </small>
                            </div>

                        </div>

                        <button
                            disabled={saving || saved}
                            className="btn btn-primary"
                            type="submit"
                        >
                            {saving ? "Сохранение..." : (saved ? "Готово" : "Сохранить")}
                        </button>
                    </form>
                )}
            </div>
        </div>
    )
}