import React, {useState, useEffect} from "react"
import styled, {keyframes} from "styled-components"
import {AiOutlineLoading3Quarters, CgSandClock} from "react-icons/all";
import {useTranslation} from "react-i18next";

interface Props {
    className?: string;
}

const rotation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  padding: 6.1rem 0;

  svg {
    font-size: 3em;
    animation: ${rotation} 1s linear infinite;
  }
`;

export const Loading: React.FC<Props> = (props) => {
    const {
        className,
    } = props;

    const [t] = useTranslation();

    return (
        <Wrapper>
            <AiOutlineLoading3Quarters/>

        </Wrapper>
    )
}
