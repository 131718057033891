import axios from "axios";
import {Bid, BidKind, Plot, Config, Period, Window} from "../entities";

export function getConfig() {
    return axios.get<Config>(`/api/config`)
        .then(res => res.data);
}

export function updateConfig(config: Config) {
    return axios.post(`/api/config`, config);
}

export function loadHistory(period: Period, kinds: BidKind[]) {
    return axios.get<Bid[]>(`/api/history?period=${period}&kinds=${kinds.join(",")}`)
        .then(res => res.data);
}

export function loadChartData(period: Period, window: Window) {
    return axios.get<Plot>(`/api/chart?period=${period}&window=${window}`)
        .then(res => res.data);
}

export function login(username: string, password: string) {
    return axios.post(`/api/login`, {username, password});
}