import {useState} from "react";

import {Period, KindParams} from "./entities";


function loadPeriod(def: Period): Period {
    return localStorage.getItem("period") as Period || def;
}

function savePeriod(period: Period) {
    localStorage.setItem("period", period);
}

export function usePeriod(def: Period) {
    const [period, _setPeriod] = useState(loadPeriod(def));

    function setPeriod(p: Period) {
        savePeriod(p);
        _setPeriod(p);
    }

    return {period, setPeriod};
}

export function createHiddenChartsStore(params: KindParams[]) {
    function setHiddenState(label: string, hidden: boolean) {
        const key = `hidden:${label}`;
        localStorage.setItem(key, hidden ? "true" : "false");
    }

    function getHiddenState(label: string): boolean {
        const key = `hidden:${label}`;
        const stored = localStorage.getItem(key);
        if (!stored) {
            return params.find(p => p.label === label)?.hidden ?? false;
        }

        return stored === "true";
    }

    return {
        getHiddenState,
        setHiddenState,
    }
}