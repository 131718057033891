import {useEffect, useState} from "react";
import {Bid, BidKind, Plot, Config, Label, Period, Window} from "../entities";
import * as api from "./client";
import {ChartItem} from "chart.js";

export function useConfig() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [config, setConfig] = useState<Partial<Config>>({});

    useEffect(() => {
        setLoading(true);
        setError(null);
        api.getConfig()
            .then(setConfig)
            .catch(setError)
            .finally(() => setLoading(false));

    }, []);

    function save(config: Config) {
        setSaving(true);
        api.updateConfig(config)
            .then(() => {
                setConfig(config);
                setSaved(true);
                setTimeout(() => setSaved(false), 1000);
            })
            .finally(() => setSaving(false));
    }

    return {
        loading,
        error,
        config,
        setConfig,
        saving,
        save,
        saved,
    }
}

export function useHistory(period: Period, kinds: BidKind[]) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [list, setList] = useState<Bid[]>([]);
    const [nonce, setNonce] = useState(0);

    useEffect(() => {
        setLoading(true);
        setError(null);
        api.loadHistory(period, kinds)
            .then(setList)
            .catch(setError)
            .finally(() => setLoading(false));

    }, [period, nonce]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!["1h", "24h"].includes(period)) {
                return;
            }

            setNonce(n => n + 1);
        }, 60*1000);
        return () => clearInterval(interval);
    }, [period, setNonce])

    return {
        loading,
        error,
        list,
    }
}

export function useChartData(period: Period, window: Window) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [nonce, setNonce] = useState(0);
    const [plot, setPlot] = useState<Plot>({labels: [], items: {}});

    useEffect(() => {
        setLoading(true);
        setError(null);
        api.loadChartData(period, window)
            .then(setPlot)
            .catch(setError)
            .finally(() => setLoading(false));

    }, [period, window, nonce]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!["1h", "24h"].includes(period)) {
                return;
            }

            setNonce(n => n + 1);
        }, 60*1000);
        return () => clearInterval(interval);
    }, [period, setNonce])

    return {
        loading,
        error,
        plot,
    }
}