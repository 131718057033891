import React from "react";
import styled from "styled-components";
import {ConfigPlate} from "../components/ConfigPlate";
import {ChartPlate} from "../components/ChartPlate";
import {LoginPlate} from "../components/LoginPlate";


const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
`

export const LoginPage: React.FC = () => {
    return (
        <Main>
            <h1 className="mb-5">Cryprorates tracker</h1>

            <LoginPlate/>
        </Main>
    )
}
